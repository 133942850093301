import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

class About extends Component {
    render() {
        const data = this.props.data.contentfulAbout;
        if (!data) {
            return null;
        }
        const body = JSON.parse(data.text.raw).content[0];
        return (
            <section id="about" className="section section--white">
                <div className="section__container">
                    <h1 className="section__container--title">{data.title}</h1>
                    <div className="section__container__text">
                        {documentToReactComponents(body)}
                    </div>
                </div>
            </section>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                contentfulAbout {
                    title
                    text {
                        raw
                    }
                }
            }
        `}
        render={(data) => <About data={data} {...props} />}
    />
);
