import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const AudioSection = data => {
    if ( !data ) {
      return null;
    }
    const spotifyEmbed = data.data.contentfulSpotify.embedCode;
    return (
        <section id="voice" className="section section--grey">
            <div className="section__container">
                <h1 className="section__container--title">Röst / Sång</h1>
                <div dangerouslySetInnerHTML = {{__html:  spotifyEmbed }}/>
            </div>
        </section>
    );
};

export default props => (
  <StaticQuery
      query={graphql`
          query {
              contentfulSpotify {
                  embedCode
              }
          }
      `}
      render={data => <AudioSection data={data} {...props} />}
  />
);
