import React, { Component } from 'react';
import Slider from 'react-slick';
import { StaticQuery, graphql } from 'gatsby';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const NextArrow = props => {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} next-arrow`}
            style={{ ...style }}
            onClick={onClick}
        />
    );
};
const PrevArrow = props => {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} prev-arrow`}
            style={{ ...style }}
            onClick={onClick}
        />
    );
};

class ImageSection extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 1000,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            pauseOnHover: true,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
        };

        if (!this.props.data) {
            return false;
        }

        const imgArr = this.props.data.contentfulImageCarousel.images;

        return (
            <section id="images" className="image-section">
                <div className="image-section__container">
                    <h1 className="image-section--title">Bilder</h1>
                    <Slider {...settings}>
                        {imgArr.map(img => {
                            return (
                                <div key={img.id}>
                                    <img
                                        className="image-section__image"
                                        srcSet={img.fluid.srcSet}
                                        sizes={img.fluid.sizes}
                                        src={img.fluid.src}
                                        alt=""
                                    />
                                    <span className="image-section__desc">
                                        {img.description}
                                    </span>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </section>
        );
    }
}

export default props => (
    <StaticQuery
        query={graphql`
            query {
                contentfulImageCarousel {
                    images {
                        id
                        description
                        fluid(maxWidth: 1200) {
                            srcSet
                            src
                            sizes
                        }
                    }
                }
            }
        `}
        render={data => <ImageSection data={data} {...props} />}
    />
);
