import React from 'react';
import SoMeLinks from './SoMeLinks';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer__container">
                <h1>Ida Löfholm</h1>
            <SoMeLinks />
            <p>© {new Date().getFullYear()}</p>
            </div>
        </div>
    );
};

export default Footer;
