import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const CurrentSection = ({ title, body }) => {
    return (
        <div className="current-section">
            <h3>{title}</h3>
            <div className="section__container__text">
                {documentToReactComponents(body)}
            </div>
        </div>
    );
};

class Current extends Component {
    createObserver = () => {
        const yellowLine = document.querySelector('.section--yellowline');
        const heroTitle = document.querySelector('.hero__title');

        const observer = new IntersectionObserver(
            (entry) => {
                if (
                    entry[0].intersectionRatio <= 0.05 &&
                    entry[0].boundingClientRect.top >= 0
                ) {
                    entry[0].target.classList.remove('hide');
                    heroTitle.classList.remove('hide');
                } else {
                    entry[0].target.classList.add('hide');
                    heroTitle.classList.add('hide');
                }
            },
            { threshold: [0, 0.05] }
        );

        observer.observe(yellowLine);
    };
    componentDidMount() {
        this.createObserver();
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }
    render() {
        const data = this.props.data.allContentfulCurrent.edges;
        if (!data) {
            return null;
        }
        return (
            <section
                id="current"
                className="section section--white section--yellowline"
            >
                <div className="section__container">
                    <h1 className="section__container--title">Aktuellt</h1>
                    {data.map((item, i) => {
                        const body = JSON.parse(item.node.text.raw).content[0];
                        return (
                            <CurrentSection
                                key={i}
                                title={item.node.title}
                                body={body}
                            />
                        );
                    })}
                </div>
            </section>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                allContentfulCurrent(sort: { fields: order, order: ASC }) {
                    edges {
                        node {
                            title
                            text {
                                raw
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => <Current data={data} {...props} />}
    />
);
