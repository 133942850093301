import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const SoMeLinks = () => {
  return (
    <div className="social-media">
        <ul className="social-media__list">
            <li className="social-media__list--item"><a href="https://www.instagram.com/idalofholm/"><FontAwesomeIcon icon={["fab", "instagram"]} size="2x"/></a></li>
        </ul>
    </div>
  )
}

export default SoMeLinks;
