import React, { Fragment } from 'react';
import Navigation from './Navigation';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'


library.add(fab, faPlayCircle);

const Layout = ({ children }) => {
    return (
        <Fragment>
            <Navigation />
            <main className="main__container">{children}</main>
        </Fragment>
    );
};

export default Layout;
