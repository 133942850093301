import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class VideoReel extends Component {
    constructor(props) {
        super(props);

        this.videoRef = React.createRef();
        this.handlePlay = this.handlePlay.bind(this);
        this.handlePause = this.handlePause.bind(this);
        this.state = {
            playing: false,
        };
    }

    handlePlay() {
        this.videoRef.current.play();
        this.setState({ playing: true });
        // Set to false in video element so that native play arrow doesent show.
        if (!this.videoRef.current.controls) {
            this.videoRef.current.controls = true;
        }
    }

    handlePause() {
        this.setState({ playing: false });
    }

    render() {
        if (!this.props.data) {
            return null;
        }
        const videoData = this.props.data.contentfulVideoReel;
        const youtubeData = this.props.data.contentfulYoutubeLink;

        const shouldShowYoutube =
            youtubeData && youtubeData.videoId !== 'dummy';

        const { playing } = this.state;
        return (
            <section id="video" className="videoreel">
                <div className="videoreel__container">
                    <h1 className="video-section--title">Rörligt</h1>
                    <div className="video-player__container">
                        <video
                            poster={videoData.poster.file.url}
                            playsInline={true}
                            ref={this.videoRef}
                            onPause={() => {
                                this.handlePause();
                            }}
                            onPlay={() => {
                                this.handlePlay();
                            }}
                            controls={false}
                            className="video-player__player"
                        >
                            <source
                                src={videoData.video.file.url}
                                type={videoData.video.file.contentType}
                            />
                        </video>
                        {!playing ? (
                            <FontAwesomeIcon
                                onClick={() => {
                                    this.handlePlay();
                                }}
                                className="video-player__icon"
                                icon={'play-circle'}
                                size="3x"
                            />
                        ) : null}
                    </div>
                    <p className="video-player__container__desc">
                        {videoData.description}
                    </p>
                    {shouldShowYoutube && (
                        <>
                            <div className="video-player__container-iframe">
                                <iframe
                                    className="video-player__player"
                                    id="ytplayer"
                                    type="text/html"
                                    width="720"
                                    height="405"
                                    src={`https://youtube.com/embed/${youtubeData.videoId}`}
                                    frameBorder="0"
                                    allowFullScreen
                                />
                            </div>
                            <p className="video-player__container__desc">
                                {youtubeData.description}
                            </p>
                        </>
                    )}
                </div>
            </section>
        );
    }
}

export default props => (
    <StaticQuery
        query={graphql`
            query {
                contentfulVideoReel {
                    description
                    poster {
                        file {
                            url
                        }
                    }
                    video {
                        file {
                            url
                            fileName
                            contentType
                        }
                    }
                }
                contentfulYoutubeLink {
                    description
                    videoId
                }
            }
        `}
        render={data => <VideoReel data={data} {...props} />}
    />
);
