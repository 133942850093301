import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

export const CvList = () => (
    <StaticQuery
        query={graphql`
            query {
                contentfulCvLista {
                    title
                    cvFile {
                        file {
                            url
                        }
                    }
                    listitem {
                        workplace
                        show
                        year
                    }
                }
            }
        `}
        render={data => (
            <section id="cv" className="cvlist-section">
                <div className="section__container">
                    <h1 className="section__container--title">
                        {data.contentfulCvLista.title}
                    </h1>
                    <table>
                        <tbody>
                            {data.contentfulCvLista.listitem.map((item, i) => {
                                return (
                                    <tr className="table-row" key={i}>
                                        <td>
                                            <p>{item.show}</p>
                                        </td>
                                        <td>
                                            <p>{item.workplace}</p>
                                        </td>
                                        <td>
                                            <p>{item.year}</p>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <a className="cv-complete" href={data.contentfulCvLista.cvFile.file.url}>
                    <span>
                        Ladda ner fullständigt CV
                    </span>
                </a>
            </section>
        )}
    />
);
