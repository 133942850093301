import React, { Component } from 'react';

class Contact extends Component {
    render() {
        return (
            <section id="contact" className="section section--white">
                <div className="section__container">
                    <h1 className="section__container--title">Kontakt</h1>
                    <form
                        className="contact-form"
                        name="contact"
                        method="POST"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                    >
                        <input type="hidden" name="form-name" value="contact" />
                        <div className="contact-form__input">
                            <label>Namn</label>
                            <input
                                className="contact-form__input--short"
                                name="namn"
                                tabIndex="1"
                                required
                                type="text"
                            />
                        </div>
                        <div className="contact-form__input">
                            <label>Email</label>
                            <input
                                className="contact-form__input--short"
                                name="email"
                                tabIndex="2"
                                spellCheck="false"
                                required
                                type="email"
                            />
                        </div>
                        <div className="contact-form__input">
                            <label>Meddelande</label>
                            <textarea
                                className="contact-form__input--long"
                                name="message"
                                tabIndex="3"
                                required
                                cols="4"
                                rows="10"
                                type="message"
                            />
                        </div>
                        <div data-netlify-recaptcha="true" />
                        <div>
                            <button type="submit">Skicka</button>
                        </div>
                    </form>
                </div>
            </section>
        );
    }
}

export default Contact;
