import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Current from '../components/Current';
import ImageSection from '../components/ImageSection';
import About from '../components/About';
import VideoReel from '../components/VideoReel';
import Contact from '../components/Contact';
import { CvList } from '../components/CvList';
import Footer from '../components/Footer';
import '../../src/index.scss';
import AudioSection from '../components/AudioSection';

const keyWords = [
    `Ida`,
    `Löfholm`,
    `Skådespelare`,
    `Skådespelerska`,
    `Teater`,
    `Theater`,
    `Film`,
    `Actor`,
    `Ìnläsning`,
    `Ljudbok`,
    `Speaker`,
    `Acting`,
    `Actress`,
    `Acting`,
    `Sång`,
    `Röst`,
    `Reel`,
    `Teaterhögskola`,
];

const IndexPage = () => (
    <Layout>
        <SEO title="Ida Löfholm Portfolio" keywords={keyWords} />
        <Hero />
        <Current />
        <ImageSection />
        <About />
        <VideoReel />
        <CvList />
        <AudioSection />
        <Contact />
        <Footer />
    </Layout>
);

export default IndexPage;
