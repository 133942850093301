import React, { Component, Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby';

class Hero extends Component {
    render() {
        const imageData = this.props.data.allFile.edges;

        return (
            <Fragment>
                <div id="hero" className="hero__image-container">
                    <picture>
                        <source
                            className="hero__image"
                            media="(max-width: 599px)"
                            srcSet={imageData[3].node.publicURL}
                        />
                        <source
                            className="hero__image"
                            media="(max-width: 1000px)"
                            srcSet={imageData[2].node.publicURL}
                        />
                        <source
                            className="hero__image"
                            media="(max-width: 1400px)"
                            srcSet={imageData[1].node.publicURL}
                        />
                        <img
                            className="hero__image"
                            src={imageData[0].node.publicURL}
                            alt="ida"
                        />
                    </picture>
                </div>

                <h1 className="hero__title">IDA LÖFHOLM</h1>
            </Fragment>
        );
    }
}

export default props => (
    <StaticQuery
        query={graphql`
            query {
                allFile(
                    filter: { sourceInstanceName: { eq: "images" } }
                    sort: { fields: name, order: ASC }
                ) {
                    edges {
                        node {
                            publicURL
                        }
                    }
                }
            }
        `}
        render={data => <Hero data={data} {...props} />}
    />
);
